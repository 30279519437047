import { Plugin } from '@capacitor/core';

export interface WiFiPlugin extends Plugin {
	version(): Promise<any>;
	checkDirect(): Promise<any>;
	connectDirect(arg: any): Promise<any>;
	disconnectDirect(): Promise<any>;
	statusDirect(): Promise<any>;
	launchSettings(): Promise<any>;
	connectHotspot(arg: any): Promise<any>;
	disconnectHotspot(): Promise<any>;
}

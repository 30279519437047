import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { PopoverController } from '@ionic/angular';
import { NavController } from '@ionic/angular';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { Preferences } from '@capacitor/preferences';
import { BluetoothClassic } from '@capacitor-inout/bluetoothclassic';
import { AlertController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { VERSION } from './version';
import { Settings, OptionsPhoneLightBuzzer, User, UserFull, Opponent, OnlineRet } from './myinterface';

@Injectable({
  providedIn: 'root'
})

export class Global {
VERSION: string = VERSION;
BASEURL: string = "https://inout.sport";
PLAYERURL: string = "https://player.inout.sport";
STATSDOCUMENTSFOLDER: string = "InOut/Stats";
STATSBASEDIRECTORY = Directory.Documents;
VIDEODOCUMENTSFOLDER: string = "InOut";
VIDEOBASEDIRECTORY = Directory.Documents;
DEBUGDOCUMENTSFOLDER: string = "InOut/Debug";
DEBUGBASEDIRECTORY = Directory.Documents;
sport: string = "tennis";
screenLandscape: boolean = true;
currentUrl: string;
refreshUI:Subject<any> = new Subject();
settings: Settings = {} as Settings;
useKeyring: boolean = true;
userFull: UserFull = {} as UserFull;
videoEncoding: string;
firmwareServerVersion: string = "";
firmwareDownloaded;
firmwareDownloadedVersion: string = "";
firmwareDownloadStatus = 0;
toast = null;
opponents: Opponent[];
deviceLineCallingLog: boolean = false;

constructor(public plt: Platform, private router: Router, private navCtrl: NavController, private alertCtrl: AlertController, private toastCtrl: ToastController, private translate: TranslateService, public popoverController: PopoverController, private httpClient: HttpClient) {
	if (typeof (<any>window).electron != "undefined") {
		(<any>window).electron.ipc.invoke("isDev").then((r) => {
			this.settings.isDev = 2;
		});
	}
	Device.getId().then((info) => {
		this.settings.deviceID = info["identifier"];
		console.log("deviceID: " + this.settings.deviceID);
	}).catch((e) => { console.log(e); });
	navCtrl.setDirection('forward');
	translate.setDefaultLang("en");
	console.log("Default browser language is: " + translate.getBrowserLang());
	this.changeLanguage(this.translate.getBrowserLang());
	if (typeof (<any>window).electron != "undefined") {
		(<any>window).electron.ipc.log((err: any, v: string) => {
			console.log(v);
		});
		(<any>window).electron.ipc.open_page((err: any, v: string) => {
			this.openPage(v, false);
		});
	}

	this.settingsLoad();
	this.userFull.identified = false;

	if (window.screen.orientation && !plt.is("desktop")) {
		this.myOrientation();
		window.screen.orientation.onchange = ((event) => {
			this.myOrientation();
		});
	}
	BluetoothClassic.hackScreenRotationBySensor();
	document.addEventListener("resume", () => { this.onResume(); }, false);
	screen.orientation.addEventListener("change", (event) => { this.refreshUI.next(true); });
	this.checkFolder(this.VIDEOBASEDIRECTORY, this.VIDEODOCUMENTSFOLDER);
	this.checkFolder(this.STATSBASEDIRECTORY, this.STATSDOCUMENTSFOLDER);
}

async checkFolder(d, p) {
	if (typeof (<any>window).electron != "undefined") {//Electron
	} else if (!this.plt.is("electron") && this.plt.is("desktop")) {//Web
	} else {//Android, iOS
		try {
			const ret = await Filesystem.stat({
				directory: d,
				path: p,
			});
		} catch(e) {
			try {
				await Filesystem.mkdir({
					directory: d,
					path: p,
				});
			} catch(e) {
				console.log("Error mkdir " + e);
			}
		}
	}
}

async userInit() {
	this.opponentsParse(this.settings.user.opponents);
	const response = await this.httpClient.post(this.PLAYERURL + "/cloud/master/login.json", "info&playerID=" + encodeURIComponent(this.settings.user.playerID) + "&token=" + encodeURIComponent(this.settings.user.token), {headers:{"content-type": "application/x-www-form-urlencoded"}}).toPromise();
	const ret = response as OnlineRet;
	this.userFull = response["userFull"] as UserFull ?? ({} as UserFull);
	this.userFull.identified = ret.error == 0;
}

async settingsLoad() {
	console.log("settingsLoad: Enter");
	if (this.plt.is("android") || this.plt.is("ios")) {
		const { value } = await Preferences.get({key: "settings"});
		if (value != null)
			this.settings = JSON.parse(value);
	} else if (typeof (<any>window).electron != "undefined") {
		await (<any>window).electron.ipc.invoke("keyringEnable", this.useKeyring);
		const value = await (<any>window).electron.ipc.invoke("load", "settings");
		if (value != null)
			this.settings = value as Settings;
	}
	this.translate.setDefaultLang("en");
	if (this.settings == null || this.settings.language === undefined) {
		this.settings = {} as Settings;
		this.settings.useBluetoothClassic = false;
		this.settings.calibrateRotationDone = false;
		this.settings.powerUser = false;
		this.settings.isDev = 0;
		this.settings.twoDevices = false;
		this.settings.secondDeviceV2V3 = false;
		this.settings.recordDuringLineCall = false;
		this.settings.videoTransport = "wifiHotspot";//this.plt.is("android") ? "wifiDirect" : "wifiHotspot";
		console.log("Default browser language: " + this.translate.getBrowserLang());
		this.changeLanguage(this.translate.getBrowserLang());
	} else {
		if (typeof this.settings.videoTransport != "string")
			this.settings.videoTransport = "wifiHotspot";//this.plt.is("android") ? "wifiDirect" : "wifiHotspot";
		await this.translate.use(this.settings.language);
		if (typeof (<any>window).electron != "undefined")
			(<any>window).electron.ipc.invoke("menuLanguage", this.settings.language);
	}

	if (this.settings.user === undefined) {
		this.settings.user = {} as User;
		this.settings.user.playerID = "0";
		this.settings.user.token = "";
	} else if (this.settings.user.playerID != "0" && this.settings.user.token != "")
		await this.userInit();

	if (this.settings.dontShowAgain === undefined)
		this.settings.dontShowAgain = Object();

	if (this.settings.optionsPhoneLightBuzzer === undefined)
		this.settings.optionsPhoneLightBuzzer = { voiceGreen:0, voiceRed:0, vibrateRed:0, flashGreen:0, flashRed:0 };

	if (this.settings.isDev === undefined)
		this.settings.isDev = 0;

	if (this.settings.twoDevices === undefined)
		this.settings.twoDevices = false;

	if (this.settings.secondDeviceV2V3 === undefined)
		this.settings.secondDeviceV2V3 = false;

	if (this.settings.recordDuringLineCall === undefined)
		this.settings.recordDuringLineCall = false;

	this.videoEncoding = this.settings.videoTransport == "bluetooth" ? "mjpeg" : "h264";
	await this.firmwareServerVersionUpdate();
}

async firmwareServerVersionUpdate() {
	this.firmwareServerVersion = await this.httpClient.post(this.BASEURL + "/firmware/version-v4", "", { headers:{ "content-type": "application/x-www-form-urlencoded"}, responseType: "text" }).toPromise();
	console.log("Version firmware on inout.sport(firmwareServerVersion): " + this.firmwareServerVersion);
}

async settingsSave() {
	console.log("settingsSave: Enter");
	let st = JSON.stringify(this.settings);
	if (this.plt.is("android") || this.plt.is("ios")) {
		await Preferences.set({key: "settings", value: st});
	} else if (this.plt.is("electron"))
		(<any>window).electron.ipc.invoke("save", "settings", st);
}

myOrientation() {
	this.screenLandscape = window.screen.orientation.type.indexOf('landscape') != -1;
}

onResume() {
	if (this.screenLandscape != (window.screen.orientation.type.indexOf('landscape') != -1)) {
		this.myOrientation();
		this.refreshUI.next(true);
	}
}

updateSport(st: string) {
	this.sport = st;
}

async backButtonAlert() {
	const alert = await this.alertCtrl.create({
		message: 'Do you want to leave the application?',
		buttons: [{
			text: "cancel",
			role: 'cancel'
		},{
			text: "Close App",
			handler: () => {
				App.exitApp();
			}
		}]
	});
	await alert.present();
}

async presentQuestion(hd, st, msg, key:string = "") {
	if (this.settings.dontShowAgain[key] !== undefined)
		return false;
	let checked = false;
	let yesClicked = false;
	const question = await this.alertCtrl.create({
		cssClass: 'basic-alert',
		header: hd,
		subHeader: st,
		message: msg,
		buttons: [{
			text: 'Yes', handler: (data) => { yesClicked = true; if (data !== undefined && data.length > 0 && data[0]) checked = true; }
		}, {
			text: 'No', cssClass: 'secondary', handler: (data) => { yesClicked = false; if (data !== undefined && data.length > 0 && data[0]) checked = true; }
		}],
		inputs: key != "" ? [{ label:"Don't show again", type:"checkbox", checked:false, value:true }] : []
	});
	await question.present();
	await question.onDidDismiss();
	if (checked) {
		this.settings.dontShowAgain[key] = true;
		this.settingsSave();
	}
	return yesClicked;
}

async presentToast(msg, duration) {
	this.toast = await this.toastCtrl.create({
		icon: "wifi-outline",
		position: "bottom",
		positionAnchor: "footer",
		message: msg,
		duration: duration,
	});
	await this.toast.present();
}

async dismissToast() {
	console.log("dismissToast " + this.toast);
	if (this.toast != null)
		this.toast.dismiss();
	this.toast = null;
}

async changeLanguage(st) {
	if (st != this.settings.language) {
		this.settings.language = st;
		await this.translate.use(this.settings.language);
		if (typeof (<any>window).electron != "undefined")
			(<any>window).electron.ipc.invoke("menuLanguage", this.settings.language);
	}
}

mytranslateP(page, st) {
	const inp = page + "." + st;
	const ret = this.translate.instant(page + "." + st);
	return ret == "" ? (this.settings.isDev == 2 && this.settings.language != "en" ? ("##" + st + "##") : st) : ret == inp ? (this.settings.isDev == 2 ? ("##" + st + "##") : st) : ret;
}

mytranslateS(sport) {
	return this.mytranslateP("selection", sport);
}

sportDevice(sport_) {
	if (typeof sport_ != "number")
		return "";
	return sport_ == 1 ? "pickleball" : "tennis";
}

mytranslate(st) {
	return this.mytranslateP(this.currentUrl, st);
}

playAudio(st) {
	var myAudio = new Audio("/assets/audio/" + "en" + "/" + st);
	myAudio.play();
}

async sleepms(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

openBrowser(url: string) {
	if (typeof (<any>window).electron != "undefined")
		(<any>window).electron.ipc.invoke("openBrowser", url);
	else
		window.open(url, "_blank");
}

openPage(url: string, close: boolean) {
	if (!close)
		this.navCtrl.setDirection('root');
	this.router.navigate(["/" + url]);
	if (close)
		this.popoverController.dismiss();
}

async presentAlert(hd, st, msg, key:string = "") {
	let checked = false;
	if (this.settings.dontShowAgain[key] !== undefined)
		return;
	const alert = await this.alertCtrl.create({
		cssClass: 'basic-alert',
		header: hd,
		subHeader: st,
		message: msg,
		buttons: [{ text:'OK', handler: data => { if (data !== undefined && data.length > 0 && data[0]) checked = true; } }],
		inputs: key != "" ? [{ label:"Don't show again", type:"checkbox", checked:false, value:true }] : []
	});
	await alert.present();
	await alert.onDidDismiss();
	if (checked) {
		this.settings.dontShowAgain[key] = true;
		this.settingsSave();
	}
}

makeIFrame(div, divWait, url) {
	divWait.style.display = "none";
	let inProcess = true;
	setTimeout(() => { if (inProcess) divWait.style.display = ""; }, 1000);
	div.innerHTML = "<iframe style='width:100%; height:100%;' frameBorder=0 scrolling='yes'></iframe>";
	div.firstChild.contentWindow.document.open();
	const content = "<html><body onload='document.forms[0].submit();'><form action='" + url + "' method='post'><input type='hidden' name='playerID' value='" + this.settings.user.playerID + "'><input type='hidden' name='token' value='" + this.settings.user.token + "'></form></body></html>";
	div.firstChild.contentWindow.document.write(content);
	div.firstChild.contentWindow.document.close();
	div.firstChild.onload = () => {
		inProcess = false;
		divWait.style.display = "none";
	};

}

getMonth(a) {
	if (typeof a == "undefined")
		return "";
	else if (a.indexOf("01.01") != -1 || a.indexOf("01-01") != -1)
		return "January";
	else if (a.indexOf("02.02") != -1 || a.indexOf("02-02") != -1)
		return "February";
	else if (a.indexOf("03.03") != -1 || a.indexOf("03-03") != -1)
		return "March";
	else if (a.indexOf("04.04") != -1 || a.indexOf("04-04") != -1)
		return "April";
	else if (a.indexOf("05.05") != -1 || a.indexOf("05-05") != -1)
		return "May";
	else if (a.indexOf("06.06") != -1 || a.indexOf("06-06") != -1)
		return "June";
	else if (a.indexOf("07.07") != -1 || a.indexOf("07-07") != -1)
		return "July";
	else if (a.indexOf("08.08") != -1 || a.indexOf("08-08") != -1)
		return "August";
	else if (a.indexOf("09.09") != -1 || a.indexOf("09-09") != -1)
		return "September";
	else if (a.indexOf("10.10") != -1 || a.indexOf("10-10") != -1)
		return "October";
	else if (a.indexOf("11.11") != -1 || a.indexOf("11-11") != -1)
		return "November";
	else if (a.indexOf("12.12") != -1 || a.indexOf("12-12") != -1)
		return "December";
	return "";
}

uint8array_to_base64(u8a: Uint8Array) {
    return window.btoa(
        Array.from(u8a).map(function (byte) {
            return String.fromCharCode(byte);
        }).join("")
    );
}

arraybuffer_to_base64(ab) {
    return this.uint8array_to_base64(new Uint8Array(ab));
}

blob_to_base64 = async (blob) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () => resolve(reader.result);
		reader.onerror = err => reject(err);
		reader.readAsDataURL(blob);
	});
};

base64_to_uint8array(st) {
	const binaryString = atob(st);
	const bytes = new Uint8Array(binaryString.length);
	for (let i = 0; i < binaryString.length; i++)
		bytes[i] = binaryString.charCodeAt(i);
	return bytes;
}

printDate(st) {
	if (typeof st != "string")
		return "";
	st = st.replace(/:/g, "");
	if (st.split("-").length > 1) {
		st = st.replace(/-(?!.*-)/, "_");
		st = st.replace(/-/g, "");
	}
	const y = st.slice(0, 4);
	let m = st.slice(4, 6);
	const d = st.slice(6, 8);
	const h = st.slice(9, 11);
	const M = st.slice(11, 13);
	const s = st.slice(13, 15);
	if (m == "01")
		m = "January";
	else if (m == "02")
		m = "February";
	else if (m == "03")
		m = "March";
	else if (m == "04")
		m = "April";
	else if (m == "05")
		m = "May";
	else if (m == "06")
		m = "June";
	else if (m == "07")
		m = "July";
	else if (m == "08")
		m = "August";
	else if (m == "09")
		m = "September";
	else if (m == "10")
		m = "October";
	else if (m == "11")
		m = "November";
	else if (m == "12")
		m = "December";
	return "" + y + " " + m + " " + d + " " + h + ":" + M + ":" + s;
}

printDuration(t) {
	if (typeof t != "number")
		return "";
	return "" + (t >= 60 ? (Math.floor(t / 60) + "m ") : "") + ((t % 60) != 0 ? ((t % 60) + "s") : "");
}

opponentsParse(st) {
	this.opponents = [];
	const opponents_ = st.split("##");
	for (let i = 0; i < opponents_.length; i++) {
		const opponent_ = opponents_[i].split("#");
		if (opponent_.length != 4)
			continue;
		this.opponents.push({playerID:opponent_[0], firstName:opponent_[1], lastName:opponent_[2], email:opponent_[3]});
	}
}

}
